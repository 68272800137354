import React from 'react';
import { graphql } from 'gatsby';
import { useI18next, useTranslation } from 'gatsby-plugin-react-i18next';
import MarkdownText from '@atoms/MarkdownText/MarkdownText';
import Button from '@atoms/Button/Button';
import Divider from '@atoms/Divider/Divider';
import Section from '@atoms/Section/Section';
import Text from '@atoms/Text/Text';
import Title from '@atoms/Title/Title';
import Avatar from '@components/Avatar/Avatar';
import CardWithBackground from '@components/Card/CardWithBackground';
import ResourcesLink from '@components/ResourcesLink';
// Modules
import Footer from '@components/Footer/Footer';
import GenericArticle from '@components/GenericArticle/GenericArticle';
import HeaderNav from '@components/HeaderNavV2/HeaderNav';
import Layout from '@components/layout';
import SocialSharing from '@components/SocialSharing/SocialSharing';
import SignupFormStories from '../../landingPage/SignupFormStories';
import './StoriesTemplate.scss';
import { renderDateTime } from '@helpers/timeHelpers';
import { getOriginalLocale } from '@helpers/GlobalHelpers';
import { useMemo } from 'react';
import LanguageNotSupported from '@components/LanguageNotSupported';
import Seo from '@components/seo';
import { withLocale } from '@hocs';

export const query = graphql`
    query($language: String!, $slug: String!) {
        aepsyCms {
            stories(filters: {slug: {eq: $slug}}, locale: "all") {
                data {
                    attributes {
                        title
                        subtitle
                        slug
                        locale
                        content
                        coverPicture {
                            data {
                                attributes {
                                    url
                                    urlSharp {
                                        childImageSharp {
                                            fluid(maxWidth: 300, quality: 80) {
                                                ...GatsbyImageSharpFluid_withWebp
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        publishedAt
                        author {
                            data {
                                attributes {
                                    description
                                    name
                                    type
                                    slug
                                    avatar {
                                        data {
                                            attributes {
                                                url
                                                urlSharp {
                                                    childImageSharp {
                                                        fluid(maxWidth: 150, quality: 80) {
                                                            ...GatsbyImageSharpFluid_withWebp
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        locales: allLocale(
            filter: { ns: { in: ["index"] }, language: { eq: $language } }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;

const StoriesTemplate: React.FC<any> = ({ data: { aepsyCms }}) => {
    const { t } = useTranslation();
    const { language } = useI18next();
    const data = aepsyCms.stories.data[0].attributes

    const {
        locale,
        title,
        content,
        coverPicture,
        subtitle,
        publishedAt,
        author: {
            data: {
                attributes: {
                    name: authorName,
                    description: authorDescription,
                    slug: authorSlug,
                    avatar,
                    type,
                },
            },
        },
    } = data;

    const isExpert = type === 'expert';

    const notSupportYet = useMemo(
        () => getOriginalLocale(locale) !== language,
        [locale, language]
    );

    const renderDefaultSubtitle = () =>
        `${t('resources.expert.interview.with')} ${authorName}`;

    const handleGoBack = () => {
        if (window) {
            window.history.back();
        }
    };
    return (
        <Layout>
            <div className="StoriesTemplate">
                <Seo title={title} description={subtitle} />
                <div className="global_theme-green">
                    {/* <TopBar /> */}
                    <HeaderNav />

                    {notSupportYet ? (
                        <div className="StoriesTemplate--Header">
                            <Section
                                container="large"
                                spacingTop="xl"
                                spacingBottom="l"
                            >
                                <Text align="center">
                                    <ResourcesLink>
                                        <Button
                                            variant="inline"
                                            label={t('button.back')}
                                        />
                                    </ResourcesLink>
                                </Text>
                                <LanguageNotSupported />
                            </Section>
                        </div>
                    ) : (
                        <>
                            {/* Header */}
                            <div className="StoriesTemplate--Header">
                                <Section
                                    container="large"
                                    spacingTop="xl"
                                    spacingBottom="l"
                                >
                                    <Text align="center">
                                        <Button
                                            variant="inline"
                                            label={t('button.back')}
                                            onClick={handleGoBack}
                                        />
                                    </Text>
                                    <Title
                                        tag="h1"
                                        align="center"
                                        font="alt"
                                        size="xxl"
                                    >
                                        {title}
                                    </Title>
                                    <Text tag="div" align="center">
                                        {(subtitle
                                            ? subtitle
                                            : renderDefaultSubtitle()) +
                                            ` | ${renderDateTime(
                                                publishedAt,
                                                'Mo MMM YYYY'
                                            )}`}
                                    </Text>
                                </Section>
                            </div>
                            {/* Content */}
                            <Section
                                container="large"
                                spacingBottom="m"
                                theme="white"
                            >
                                <div className="g_center g_1_3 StoriesTemplate--Avatar">
                                    {isExpert ? (
                                        <Avatar
                                            align="center"
                                            src={avatar.data.attributes.url}
                                            size="ml"
                                        />
                                    ) : (
                                        <CardWithBackground
                                            url={
                                                coverPicture?.data
                                                    ? coverPicture.data
                                                          ?.attributes.url
                                                    : ''
                                            }
                                            urlSharp={
                                                coverPicture.data?.attributes?.urlSharp ? coverPicture.data?.attributes?.urlSharp : ''
                                           }
                                            
                                        />
                                    )}
                                </div>
                                <GenericArticle>
                                    {isExpert ? (
                                        <Text tag="div">
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: content,
                                                }}
                                                className="MarkdownText"
                                            ></div>
                                        </Text>
                                    ) : (
                                        <MarkdownText content={content} />
                                    )}
                                </GenericArticle>

                                <Section spacingTop="m">
                                    <div className="g_center g_2_3">
                                        <SignupFormStories />
                                    </div>
                                </Section>

                                {/* share */}
                                <Divider spacing="xl" invisible />
                                <div className="g_clearfix">
                                    <div className="g_2_3 g_center">
                                        <Divider spacing="l" />
                                        <SocialSharing
                                            title={title}
                                            summary={subtitle}
                                            theme="dark"
                                        />
                                        <Divider spacing="l" />
                                    </div>
                                </div>
                                {/* Author */}
                                <Section
                                    container="large"
                                    theme="white"
                                    spacingBottom="s"
                                >
                                    <div className="StoriesTemplate--Author g_1_2 g_center">
                                        {/* Avatar */}
                                        <div className="StoriesTemplate--FooterAvatar">
                                            <Avatar
                                                size="m"
                                                align="center"
                                                src={avatar.data.attributes.url}
                                                urlSharp={avatar.data.attributes.urlSharp ? avatar.data.attributes.urlSharp : undefined}
                                            />
                                        </div>
                                        <Divider invisible spacing="s" />
                                        {/* Author */}
                                        <Title size="l" align="center">
                                            {authorName}
                                        </Title>
                                        <Divider invisible spacing="xxs" />
                                        {/* Description */}
                                        <Text align="center">
                                            {authorDescription}
                                        </Text>
                                        {isExpert ? (
                                            <a
                                                href={`https://app.aepsy.com/t/${authorSlug}?apy_src=interview`}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <Button
                                                    label={t(
                                                        'button.book.info.call'
                                                    )}
                                                />
                                            </a>
                                        ) : (
                                            <a
                                                href="https://aepsy.com/about"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <Button
                                                    label={t('learn.more')}
                                                />
                                            </a>
                                        )}
                                    </div>
                                </Section>
                            </Section>
                        </>
                    )}

                    <Footer />
                </div>
            </div>
        </Layout>
    );
};

export default withLocale(StoriesTemplate);
